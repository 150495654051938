/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery.mmenu@7.3.3/dist/jquery.mmenu.min.js
 * - /gh/heartyme/web.hearty.me@40929/js/alertify.custom.min.js
 * - /npm/firebase@8.10.1/firebase-app.js
 * - /npm/firebase@8.10.1/firebase-messaging.js
 * - /gh/heartyme/web.hearty.me@8f42da0f5d0777e764dcb7a6d4ad2a65fb89a759/js/common.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
